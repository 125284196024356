
import { API_ROUTES } from '../../utils/constants';
import { AxiosBase } from './index';

export default class UserService extends AxiosBase {
  getAllUsers = async (body) => {
    const { page, limit } = body
    try {
      const response = await this.requests.get(`${API_ROUTES.USERS.USER}?page=${page}&limit=${limit}`);
      return response;
    } catch (err) {
      throw err
    }
  }

  getUserStats = async (body) => {
    const { type } = body
    try {
      const response = await this.requests.get(`${API_ROUTES.USERS.USER}/${API_ROUTES.USERS.STATS}?type=${type}`);
      return response;
    } catch (err) {
      throw err
    }
  }

  blockUser = async (id) => {
    try {
      const response = await this.requests.put(`${API_ROUTES.USERS.USER}/${id}/block`);
      return response;
    } catch (err) {
      throw err
    }
  }

  getUserContacts = async (phoneNumber,code) => {
    try {
      const response = await this.requests.get(`${API_ROUTES.USERS.CONTACTS}/${phoneNumber}?c=${code}`);
      return response;
    } catch (err) {
      throw err
    }
  }

  updateUserContacts = async (phoneNumber, code, data, botId) => {
    try {
      const response = await this.requests.post(`${API_ROUTES.USERS.CONTACTS}/${phoneNumber}/${botId}?c=${code}&isBrowser=true`, { data });
      return response;
    } catch (err) {
      throw err
    }
  }

  editUser = async (id, body) => {
    try {
      const response = await this.requests.put(`${API_ROUTES.USERS.USER}/${id}`, body);
      return response;
    } catch (err) {
      throw err
    }
  }

  deleteUserContacts = async (phoneNumber, code, data, botId) => {
    try {
      const response = await this.requests.delete(`${API_ROUTES.USERS.CONTACTS}/${phoneNumber}/${botId}?c=${code}&isBrowser=true`, { data });
      return response;
    } catch (err) {
      throw err
    }
  }

  getFriendsRecommendations = async (phoneNumber) => {
    try {
      const response = await this.requests.get(`user/${phoneNumber}/${API_ROUTES.USERS.FRIENDS_RECOMMENDATIONS}`);
      return response;
    } catch (err) {
      throw err
    }
  };

  getRecommendations = async (phoneNumber) => {
    try {
      const response = await this.requests.get(`user/${phoneNumber}/${API_ROUTES.USERS.RECOMMENDATIONS}`);
      return response;
    } catch (err) {
      throw err
    }
  }

}