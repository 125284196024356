import React, { useCallback, useEffect, useState } from "react";
import { arrowDownIcon, logo_cropped, trash } from "../../assets";
import { useParams, useSearchParams } from "react-router-dom";

import UserService from "../../services/api/userService";
import styles from "./ImportContacts.module.scss";
import { Search, Trash2, X } from 'lucide-react';

const userService = new UserService();

const Loader = () => {
  return (
    <div className={styles.loader}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        width="40"
        height="40"
        className={styles.svgLoader}
      >
        <circle
          cx="50"
          cy="50"
          r="40"
          stroke="#3498db"
          strokeWidth="5"
          fill="none"
          strokeDasharray="188.4"
          strokeDashoffset="0"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="0"
            to="-188.4"
            dur="1s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </div>
  );
};

const RecommendedList = ({ data, mode }) => {
  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber?.startsWith("972")) {
      // Replace "972" with "0"
      phoneNumber = "0" + phoneNumber.slice(3);
    }

    // Add a hyphen at the 4th position
    if (phoneNumber.length > 4) {
      return phoneNumber.slice(0, 3) + "-" + phoneNumber.slice(3);
    }

    return phoneNumber;
  };
  return (
      <div className={styles.recommendedList}>
      {data?.map((item, index) =>
        <div key={index} className={`${styles.recommendedCard} ${mode === 'friends' ? styles.recommendedCardFriends : ''}`}>
          <div className={styles.recommendedListItems} >
            <div className={styles.recommendedListItemWrapper}>
              <p className={styles.recommendedListItem}>{item.businessName}</p>
              <p className={styles.recommendedListItem}>{item.categories?.join(", ")}</p>
              <p className={styles.recommendedListItem}>{item.location}</p>
              <p className={styles.recommendedListItem}>
                <a href={`tel:${formatPhoneNumber(item?.phoneNumber)}`}>
                  {formatPhoneNumber(item?.phoneNumber)}
                </a>
              </p>
              {item.recommendedBy && (
                <p className={styles.recommendedBy}>הומלץ על ידי {item.recommendedBy}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const requestStates = {
  preStart: 0,
  loading: 1,
  done: 2,
  error: 3,
};

const modalStates = {
  close: 0,
  open: 1,
  loading: 2,
  error: 3,
};

const OneContact = ({
  contact,
  deleteModalState,
  handleCancelDelete,
  handleDeleteClick,
  handleConfirmDelete,
  contactToDelete,
}) => {
  const [recommendations, setRecommendations] = useState([]);
  const [isSelected, setIsSelected] = useState(false);

  const [requestState, setRequestState] = useState(requestStates.preStart);

  const getRecommendedBusinesses = async () => {
    setRequestState(requestStates.loading);
    try {
      const recommendations = await userService.getRecommendations(contact.phoneNumber);
      if (recommendations.error) throw recommendations.error;
      setRecommendations(recommendations);
      setRequestState(requestStates.done);
    } catch (error) {
      console.log('error', error);
      setRequestState(requestStates.error);
    }
    
  };

  const toggleRecommendations = () => {
    setIsSelected(prev => !prev);
    if (requestState === requestStates.preStart) getRecommendedBusinesses();
  };

  return <>
    <div className={styles.contactsDetails}>
      <div className={styles.contacts}>
        <div
          className={styles.contactNameContainer}
          onClick={toggleRecommendations}
        >
          <img
            src={arrowDownIcon}
            className={`${styles.arrowIcon} ${isSelected ? styles.arrowIconRotate : ""}`}
            alt=""
          />
          <span className={styles.name}>{contact.name}</span>
        </div>
      </div>
      <Trash2
        onClick={() => handleDeleteClick(contact)}
        src={trash}
        size={40}
        alt="minus"
        className={styles.minus}
      />
      {/* Delete Popup */}
      <DeletePopup
        deleteModalState={deleteModalState}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        contactName={contactToDelete?.name}
      />
    </div>
    {
      !isSelected ? (
        null
      ) : requestState === requestStates.loading ? (
        <Loader />
      ) : recommendations.length === 0 ? (
        <p dir="rtl" className={styles.noRecommendations}>אין המלצות</p>
      ) : (
        <RecommendedList data={recommendations} mode="friends" />
      )
    }
  </>;
};

const ImportContacts = () => {
  const params = useParams()
  const [contactToDelete, setContactToDelete] = useState(null);
  const [deleteModalState, setDeleteModalState] = useState(modalStates.close);
  const [contacts, setContacts] = useState([])
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("friends"); // 'friends' or 'experts'
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCodeValid, setIsCodeValid] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [allExpertsRecommendations, setAllExpertsRecommendations] = useState([]);
  const code = searchParams?.get("c")
  const botId = params.botId;
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true)
        if (!contacts?.length) {
          const _contacts = await userService.getUserContacts(params.phoneNumber, code)
          if (_contacts && _contacts.length > 0) {
            setContacts(_contacts)
            setIsCodeValid(true)

            if (activeTab === 'experts') {
              await fetchAllExpertsRecommendations(params.phoneNumber);
            }

          }
          else {
            setContacts([])
            setIsCodeValid(false)
          }
          setIsLoading(false)
        }
      } catch (error) {
        console.log('error', error)
        setIsCodeValid(false)
        setIsLoading(false)
      }
    })()
  }, [])

  const fetchAllExpertsRecommendations = async (phoneNumber) => {
    setIsLoading(true);

    try {
      const recommendations = await userService.getFriendsRecommendations(phoneNumber);
      if (!recommendations.error && recommendations.length > 0) {
        setAllExpertsRecommendations(recommendations);
      }
    } catch (error) {
      console.log('Error fetching recommendations for', phoneNumber, error);
    }

    setIsLoading(false);
  };

  const filteredContacts = contacts.filter(contact =>
    contact.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredExperts = allExpertsRecommendations.filter(expert =>
    expert.businessName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    expert.ownerName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    expert.recommendedBy?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDeleteClick = useCallback((contact) => {
    setContactToDelete(contact);
    setDeleteModalState(modalStates.open);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (contactToDelete) {
      try {
        setDeleteModalState(modalStates.loading);
        await userService.deleteUserContacts(params.phoneNumber, code, [contactToDelete], botId);
        setContacts((prevContacts) =>
          prevContacts.filter((contact) =>
            contact.phoneNumber !== contactToDelete.phoneNumber
          )
        );
        setDeleteModalState(modalStates.close);
        setContactToDelete(null);
      } catch (error) {
        console.error('Error saving contacts:', error);
        setDeleteModalState(modalStates.error);
      }
    }
  }, [contactToDelete, params.phoneNumber, code, botId]);

  const handleCancelDelete = useCallback(() => {
    setDeleteModalState(modalStates.close);
    setContactToDelete(null);
  }, []);


  const handleTabChange = async (tab) => {
    setActiveTab(tab);
    if (tab === 'experts' && allExpertsRecommendations.length === 0) {
      await fetchAllExpertsRecommendations(params.phoneNumber);
    }
  };

  if (!isCodeValid && !isLoading) {
    console.log('invalid')
    return (
      <div className={styles.invalidCode}>
        <p>כדי לראות רשימת חברים מעודכנת, יש לחזור לצ'אט ולהמשיך בו את התהליך</p>
      </div>
    )
  }

  if (isLoading) {
    return <div className={styles.invalidCode}>
      <h3>Loading...</h3>
    </div>
  }

  return (
    <div className={styles.importContacts}>
      <div className={styles.importContactsContainer}>
        <img src={logo_cropped} alt="logo" className={styles.logo} />
        <div className={styles.tabsContainer}>
          <button
            className={`${styles.tab} ${activeTab === 'experts' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('experts')}
          >
            מומחים מומלצים
          </button>
          <button
            className={`${styles.tab} ${activeTab === 'friends' ? styles.activeTab : ''}`}
            onClick={() => handleTabChange('friends')}
          >
            חברים ממליצים
          </button>
        </div>

        {/* Search Bar */}
        <div className={styles.searchContainer} dir="rtl">
          <input
            type="text"
            placeholder={activeTab === 'friends' ? "חיפוש חברים" : "חיפוש מומחים"}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={styles.searchInput}
          />
          <div className={styles.iconWrapper}>
            <Search size={16} />
          </div>
        </div>

        <h2 className={styles.sectionHeader}>
          {activeTab === 'friends' ? (
            contacts.length ? 'החברים הממליצים שלך' : 'עדיין לא הוספת חברים'
          ) : (
            filteredExperts.length > 0 ? 'ההמלצות של החברים שלך' : 'אין המלצות של חברים על מומחים'
          )}
        </h2>

        <div className={styles.container}>
          {activeTab === 'friends' ? (
            // Friends Tab Content
            filteredContacts?.map((contact) => (
              <div key={contact.phoneNumber}>
                <OneContact
                  contact={contact}
                  deleteModalState={deleteModalState}
                  handleCancelDelete={handleCancelDelete}
                  handleDeleteClick={handleDeleteClick}
                  handleConfirmDelete={handleConfirmDelete}
                  contactToDelete={contactToDelete}
                />
              </div>
            ))
          ) : (
            // Experts Tab Content
            <div className={styles.expertsContainer}>
              <RecommendedList data={filteredExperts} mode="experts" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const DeletePopup = ({ deleteModalState, onClose, onConfirm, contactName }) => {
  if (deleteModalState === modalStates.close) return null;

  return (
    <div className={styles.deletePopup}>
      <div className={styles.deletePopupContainer}>
        {/* X button */}
        <div className={styles.deletePopupClose} onClick={onClose}>
          <X size={24} />
        </div>

        {/* Content */}
        <div className={styles.deletePopupContent}>
          <h2 className={styles.deletePopupTitle}>מחיקת איש קשר</h2>

          <p className={styles.deletePopupText}>
            בחרת למחוק את <span className={styles.deletePopupTextContactName}>{contactName}</span> מרשימת החברים הממליצים שלך.
          </p>

          <p className={styles.deletePopupText}>
            האם ברצונך להמשיך?
          </p>

          {/* Buttons */}
          <div className={styles.deletePopupButtonsContainer}>
            {
              deleteModalState === modalStates.loading ? (
                <Loader />
              ) : deleteModalState === modalStates.error ? (
                'שגיאה בתהליך המחיקה :/' 
              ) : (<>
                <button
                  onClick={onConfirm}
                  className={`${styles.deletePopupButton} ${styles.deletePopupButtonConfirm}`}
                >
                  כן, מחיקה
                </button>
                <button
                  onClick={onClose}
                  className={`${styles.deletePopupButton} ${styles.deletePopupButtonCancel}`}
                >
                  ביטול
                </button>
              </>)
            }
          </div>
        </div>
      </div>
    </div>
  );
};


export default ImportContacts;
