import axios from 'axios';
import { API_ROUTES, CONFIG } from '../../utils/constants';
import AppSecureStorage from '../storage/secureStorage';
import { toast } from 'react-toastify';

const storage = new AppSecureStorage()
export class AxiosBase {
  constructor() {
    this.instance = axios.create({
      baseURL: API_ROUTES.BASE_URL,
      timeout: CONFIG.TIMEOUT,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.instance.interceptors.request.use((config) => {
      const accessToken = storage.get(process.env.REACT_APP_ACCESS_TOKEN_KEY);
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    });
  }

  responseBody = (response) => {
    return response.data;
  };

  responseError = (error) => {
    if (error.response?.status === 401) {
      this.showSessionExpiredModal();
    }
    throw error.response;
  };
  
  showSessionExpiredModal = async () => {
    const allowedRedirects = ["login", "business-signup", "m-c"];
    const currentUrl = window.location.href;
  
    if (!allowedRedirects.some(substring => currentUrl.includes(substring))) {
      toast.error("Session expired. Redirecting to login...");
      await new Promise(resolve => setTimeout(resolve, 2000));
      window.location.href = '/login';
    }
  };
  

  serializeParams = function (url,params) {
    var str = [];
    for (var p in params)
      if (params.hasOwnProperty(p) && (params[p] !== undefined && params[p] !== null)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
      }
    return str.length > 0 ? `${url}?${str.join("&")}` : url;
  }

  requests = {
    get: (url) =>
      this.instance.get(url).then(this.responseBody).catch(this.responseError),
    post: (url, body) =>
      this.instance
        .post(url, body)
        .then(this.responseBody)
        .catch(this.responseError),
    put: (url, body) =>
      this.instance
        .put(url, body)
        .then(this.responseBody)
        .catch(this.responseError),
    patch: (url) =>
      this.instance.patch(url).then(this.responseBody).catch(this.responseError),
    delete: (url, body) =>
      this.instance
        .delete(url, { data: body })
        .then(this.responseBody)
        .catch(this.responseError)
  }
}